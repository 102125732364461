export const mediatorRegisterFormFields = {
  email: {
    type: "email",
    label: "Email",
    name: "email",
    value: "",
    error: "",
    payloadID: "email",
  },
  password: {
    type: "password",
    label: "Password",
    name: "password",
    value: "",
    error: "",
    payloadID: "password",
  },
  confirmPassword: {
    type: "password",
    label: "Confirm Password",
    name: "confirmPassword",
    value: "",
    error: "",
    payloadID: "confirm_password",
  },
  state_select: {
    label: "State",
    placeholder: "Select State",
    name: "state",
    error: "",
    payloadID: "state",
  },
  district_select: {
    label: "District",
    name: "district",
    placeholder: "Select District",
    error: "",
    payloadID: "district",
  },
  designation: {
    label: "Designation",
    name: "designation",
    placeholder: "",
    error: "",
    payloadID: "designation",
  },

  // contactNumber: {
  //   type: "number",
  //   label: "Contact Number",
  //   name: "contactNumber",
  //   value: "",
  //   error: "",
  //   payloadID: "contactNumber",
  // },
  // userType: {
  //   type: "text",
  //   label: "UserType",
  //   name: "userType",
  //   value: "",
  //   error: "",
  //   payloadID: "account_type",
  // },
  contact_number: {
    type: "text",
    label: "Contact Number",
    name: "contact_number",
    value: "",
    error: "",
    payloadID: "contact_number",
  },
  // schoolId: {
  //   type: "number",
  //   // label: "School Id",
  //   label: "Id",
  //   name: "schoolId",
  //   value: "",
  //   error: "",
  //   payloadID: "mediator_id",
  // },
  schoolName: {
    type: "text",
    // label: "School Name",
    label: "Instituition Name",
    name: "schoolName",
    value: "",
    error: "",
    payloadID: "school_name",
  },
  schoolAddress: {
    type: "text",
    // label: "School Address",
    label: "Address",
    name: "schoolAddress",
    value: "",
    error: "",
    payloadID: "school_address",
  },

  //   bankName: {
  //     type: "text",
  //     label: "Bank Name",
  //     name: "bankName",
  //     value: "",
  //     error: "",
  //     payloadID: "bank_name",
  //   },
  //   bankIfsc: {
  //     type: "text",
  //     label: "Bank IFSC",
  //     name: "bankIfsc",
  //     value: "",
  //     error: "",
  //     payloadID: "bank_ifsc",
  //   },
  //   bankAccountNo: {
  //     type: "number",
  //     label: "Bank Account No",
  //     name: "bankAccountNo",
  //     value: "",
  //     error: "",
  //     payloadID: "bank_acc_no",
  //   },
};

const studentDetailColumnsObj = {
  aadhar_card: {
    key: "aadhar_card",
    label: "Aadhaar Card",
    posId: 10,
  },
  email: { key: "email", label: "Email", posId: 5 },
  dob: { key: "dob", label: "Date of Birth", posId: 6 },
  date_of_apply: { key: "date_of_apply", label: "Date of Apply", posId: 23 },
  address: { key: "address", label: "Address", posId: 14 },
  application_id: { key: "application_id", label: "Application ID", posId: 1 },
  contact1: { key: "contact1", label: "Contact 1", posId: 16 },
  contact2: { key: "contact2", label: "Contact 2", posId: 18 },
  emi_amount: { key: "emi_amount", label: "EMI Amount", posId: 26 },
  current_emi: { key: "current_emi", label: "Current EMI", posId: 25 },
  gender: { key: "gender", label: "Gender", posId: 7 },
  grade: { key: "grade", label: "Grade", posId: 11 },
  gstin_number: { key: "gstin_number", label: "GSTIN", posId: 28 },
  id_card_number: { key: "id_card_number", label: "ID CARD", posId: 3 },
  is_applied: { key: "is_applied", label: "Registered", posId: 21 },
  occupation: { key: "occupation", label: "Occupation", posId: 19 },
  organization_name: {
    key: "organization_name",
    label: "Organization",
    posId: 20,
  },
  pan_card: { key: "pan_card", label: "Pan Card", posId: 11 },
  parent_name: { key: "parent_name", label: "Parent Name", posId: 12 },
  phone_number: { key: "phone_number", label: "Phone Number", posId: 13 },
  reference1: { key: "reference1", label: "Reference 1", posId: 15 },
  reference2: { key: "reference2", label: "Reference 2", posId: 17 },
  school_fee: { key: "school_fee", label: "School Fee", posId: 9 },
  school_id: { key: "school_id", label: "School ID", posId: 2 },
  school_name: { key: "school_name", label: "School Name", posId: 8 },
  select_loan_type: {
    key: "select_loan_type",
    label: "Select Loan Type",
    posId: 24,
  },
  status: { key: "status", label: "Status", posId: 22 },
  student_name: { key: "student_name", label: "Student Name", posId: 4 },
};

export const studentDetailColumns = Object.values(studentDetailColumnsObj).sort(
  (a, b) => (a.posId > b.posId ? 1 : b.posId > a.posId ? -1 : 0)
);

export const getRegisterFormPayload = (formDataObj) => {
  const currentUserRole = sessionStorage.getItem("role");
  let userRoleNumber = 0;
  if (currentUserRole === "borrower") {
    userRoleNumber = 3;
  } else if (currentUserRole === "mediator") {
    userRoleNumber = 2;
  } else if (currentUserRole === "lender") {
  } else {
    console.log("error");
    return;
  }

  const payload = Object.keys(formDataObj).reduce((prev, curr) => {
    return { ...prev, [curr]: formDataObj[curr].value };
  }, {});
  payload.account_type = userRoleNumber;
  return payload;
};
