import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import signIn from "../assets/images/730_generated-removebg-preview.png";
import {
  BORROWER,
  MEDIATOR,
  LENDER,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  LOGIN_TIME,
  USER,
} from "../utils/constant/index";
import { loginUserApi } from "../api/login/login.api";
import Spinner from "../components/Spinner/Spinner";
import logoHorseSrc from "../assets/images/thar_logo_1.png";

const Login = ({ setRole }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    // userType: "",
  });
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsClicked(true);
    let userType = null; // to set user type from api response

    try {
      const { data, status } = await loginUserApi(formData);

      if (status === 200) {
        if (data.Account_type === 3) {
          userType = BORROWER;
        } else if (data.Account_type === 2) {
          userType = MEDIATOR;
        } else if (data.Account_type === 1) {
          userType = LENDER;
        }
        if (userType === BORROWER) {
          setRole(BORROWER);
          sessionStorage.setItem("role", BORROWER);
          navigate("/applicant/home");
          // window.location.href = `${protocol}//application.${window.location[_url]}/status`;
          // navigate("/register"); //replace with 1st private route to be shown
        } else if (userType === MEDIATOR) {
          setRole(MEDIATOR);
          sessionStorage.setItem("role", MEDIATOR);
          localStorage.setItem(
            USER,
            JSON.stringify({ name: data.school_name, med_id: data.mediator_id })
          );
          navigate("/institution/home");
          // window.location.href = `${protocol}//application.${window.location[_url]}/`;
          // navigate("/register"); //replace with 1st private route to be shown
        } else if (userType === LENDER) {
          setRole(LENDER);
          sessionStorage.setItem("role", LENDER);
          navigate("/admin/dashboard");
          // window.location.href = `${protocol}//application.${window.location[_url]}/status`;
          // navigate("/register"); //replace with 1st private route to be shown
        } else {
          console.log("error");
          return;
        }
        localStorage.setItem(ACCESS_TOKEN, data.token.access);
        localStorage.setItem(REFRESH_TOKEN, data.token.refresh);
        localStorage.setItem(LOGIN_TIME, Date.now());
        return;
      } else {
        alert("Something went wrong.");
      }
    } catch (err) {
      console.log(err);
      if (err?.request?.status === 404) {
        alert("Invalid Credentials");
        return;
      }
      alert("Something went wrong! Please try again later");
    } finally {
      setIsClicked(false);
    }

    // let { userType } = formData;

    /* 
    TODO:api call for login, redirect to the correct subdomain after fetching the role from api
    if invlaid creds, then redirect to register
    */
    // let _url = process.env.NODE_ENV === "production" ? "hostname" : "host";
    // let { protocol } = window.location;
  };

  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const userTypesArray = [BORROWER, MEDIATOR, LENDER];

  return (
    <div className="w-full relative h-full flex items-center justify-center">
      <div className="w-1/2 h-full bg-indigo-500 flex items-center justify-center">
        <img src={signIn} alt="" className="aspect-auto w-2/3" />
      </div>
      <div className="w-1/2 h-full flex flex-col items-center justify-center ">
        <div className="w-[320px] h-[70px]  p-1">
          <img src={logoHorseSrc} alt="logo_image" className="w-full h-full" />
        </div>
        <br />
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block font-medium text-gray-500" htmlFor="email">
              Email
            </label>
            <input
              type={"email"}
              id="email"
              name="email"
              onChange={onInputChange}
              value={formData.email}
              placeholder="Enter Email"
              className="leading-10 indent-4 border-[1px] min-w-[300px] w-full max-w-full border-light-grey"
            />
          </div>
          <div className="mb-4">
            <label
              className="block font-medium text-gray-500"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type={"password"}
              id="password"
              name="password"
              onChange={onInputChange}
              value={formData.pwd}
              placeholder="Enter Password"
              className="leading-10 indent-4 border-[1px] min-w-[150px] w-full max-w-full border-light-grey"
            />
          </div>
          {/* <div className="mb-4 "> */}
          {/* <label
              className="block font-medium text-gray-500"
              htmlFor="userType"
            >
              User Type
            </label> */}
          {/* {userTypesArray.map((userTypeItem, index) => (
            <div
              key={index}
              className="flex flex-row gap-2 justify-start items-center">
              <input
                type={"radio"}
                id="userType"
                name="userType"
                onChange={onInputChange}
                value={userTypeItem}
                checked={userTypeItem === formData.userType}
                className="h-4 w-4 bg-slate-100 border-2 border-slate-600 text-gray-500"
              />
              <label
                className="block font-medium text-gray-600 capitalize"
                htmlFor="password">
                {userTypeItem}
                {toUpperCaseFirstLetter(userTypeItem)}
              </label>
            </div>
          ))
          } */}
          {/* <div className="flex flex-row gap-2 justify-start items-center">
              <input
                type={"radio"}
                id="userType"
                name="userType"
                onChange={onInputChange}
                value={"mediator"}
                checked={"mediator" === formData.userType}
                className="w-4 h-4 bg-slate-100 border-2 border-slate-600"
              />
              <label className="block" htmlFor="password">
                Mediator
              </label>
            </div> */}
          {/* </div> */}
          <br />
          <div className="w-full text-center h-auto flex justify-center">
            <button
              className="py-2 px-4 uppercase text-sm bg-blue-300 rounded-md flex items-center h-10 leading-10"
              type="submit"
            >
              Login
              {isClicked && (
                <div className="h-2/3 w-full ml-2 l">
                  <Spinner />
                </div>
              )}
            </button>
          </div>
        </form>

        {/* <div className="mt-4 border-[1px] border-blue-100 p-2">
          <div className="text-center text-sm">Don't have an account</div>
          <div className="text-center text-sm mb-2">Sign Up Now</div>
          <Link className="underline m-1" to="/applicant/register">
            Applicant
          </Link>
          <Link className="underline m-1" to="/institution/register">
            Institution
          </Link>
          <Link className="underline m-1" to="/lender/register">
            Lender
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
