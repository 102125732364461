export const bLinks = [
  {
    to: "/applicant/home",
    name: "Home",
  },
  {
    to: "/applicant/new-application",
    name: "New Application",
  },
  //   { to: "/", name: "Logout" },
];

export const aLinks = [
  {
    to: "/admin/dashboard",
    name: "All Applications",
  },
  {
    to: "/admin/all-institutes",
    name: "Institution List",
  },
  //   { to: "/", name: "Logout" },
];

export const mLinks = [
  {
    to: "/institution/home",
    name: "Home",
  },
  // {
  //   to: "/institution/all-student-details",
  //   name: "Student List",
  // },
  //   { to: "/", name: "Logout" },
];
