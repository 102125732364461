import React from "react";
import spinStyle from "./Spinner.module.css";

const Spinner = () => {
  return (
    <div className={`${spinStyle["spinner-container"]}`}>
      <div className={`${spinStyle["loading-spinner"]}`}></div>
    </div>
  );
};

export default Spinner;
