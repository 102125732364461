import React from "react";

const TableHead = ({ children }) => {
  return (
    <div className="w-full grid grid-cols-auto-fit grid-flow-col justify-items-center px-4">
      {children}
    </div>
  );
};

export default TableHead;
