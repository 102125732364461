import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getToken } from "./Common";
function PrivateRoute({ children }) {
  let auth = getToken() ? true : false;
  let location = useLocation();

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

// handle the private routes
// function PrivateRoute({ element: Component, ...rest }) {
//   console.log(Component, ...rest);
//   return (
//     <Route
//       {...rest}
//       element={(props) =>
//         getToken() ? (
//           <Component {...props} />
//         ) : (
//           <Navigate
//             exact
//             to={{ pathname: "/", state: { from: props.location } }}
//           />
//         )
//       }
//     />
//   );
// }

export default PrivateRoute;
