import React from "react";

const Label = ({ children, htmlFor, required }) => {
  return (
    <label className="block pb-3" htmlFor={htmlFor}>
      {children}
      {required === true && <span className="text-red-500">*</span>}
    </label>
  );
};

export default Label;
