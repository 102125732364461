import React from "react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  ACCESS_TOKEN,
  BORROWER,
  LENDER,
  LOGIN_TIME,
  MEDIATOR,
  REFRESH_TOKEN,
  USER,
} from "../../utils/constant";
import AdminNav from "./AdminNav";
import BorrowerNav from "./BorrowerNav";
import MedNav from "./MedNav";
import logoHorseSrc from "../../assets/images/thar_logo_1-removebg-preview.png";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem(ACCESS_TOKEN);
  const role = sessionStorage.getItem("role");

  if (!token || location.pathname === "/") {
    return null;
  }

  if (token) {
    return (
      <header className="h-[80px] bg-slate-100 -center w-full z-50 fixed top-0 left-0 grid items-center shadow-sm shadow-slate-300">
        <nav className="relative  px-4 flex flex-row justify-between items">
          <div className="w-[200px] h-[60px]  p-1">
            <NavLink to={"/"}>
              <img
                src={logoHorseSrc}
                alt="logo_image"
                className="w-full h-full"
              />
            </NavLink>
          </div>
          <div className="flex justify-end items-center">
            {role === BORROWER ? (
              <BorrowerNav />
            ) : role === LENDER ? (
              <AdminNav />
            ) : role === MEDIATOR ? (
              <MedNav />
            ) : null}
            <button
              className="capitalize font-medium p-2 text-base leading-[22px]"
              onClick={() => {
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.removeItem(REFRESH_TOKEN);
                sessionStorage.removeItem("role");
                localStorage.removeItem(USER);
                localStorage.removeItem(LOGIN_TIME);

                navigate("/");
              }}
            >
              Logout
            </button>
          </div>
        </nav>
      </header>
    );
  }
};

export default Navbar;
