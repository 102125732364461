import axios from "axios";
import { BASE_URL } from "../../utils/constant/index";
const LOGIN_URL = "/login";

// create axios instance
const loginUserAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {},
});

// save bank detail to backend
export const loginUserApi = async (payload = null) => {
  try {
    const response = await loginUserAxiosInstance({
      url: LOGIN_URL,
      method: "post",
      data: payload,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export default loginUserApi;
