export const INSTITUTION = "institution";
const medRoutes = [
  // {
  //   path: "/",
  //   element: () => require("react-router-dom").Navigate({ to: "/login" }),
  // },
  {
    path: "/institution/login",
    element: require("../pages/Login").default,
  },
  // {
  //   path: "/institution/register",
  //   element: require("../pages/mediator/Register").default,
  // },
  {
    path: "/institution/home",
    element: require("../pages/mediator/Home").default,
  },
  // {
  //   path: "/institution/all-student-details",
  //   element: require("../pages/mediator/StudentDetail").default,
  // },
  {
    path: "*",
    element: require("../pages/Error").default,
  },
];

export default medRoutes;
