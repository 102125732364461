import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import PrivateRoute from "./utils/PrivateRoute";
import { default as MRegister } from "./pages/mediator/Register";
import { default as BRegister } from "./pages/borrower/Register";
import { default as LRegister } from "./pages/lender/Register";

function App() {
  const BORROWER = "borrower";
  const LENDER = "lender";
  const MEDIATOR = "mediator";

  const [role, setRole] = useState(null);

  useEffect(() => {
    // let subdomain = window.location.hostname.split(".")[0];
    // subdomain === "application"
    //   ? setRole(BORROWER)
    //   : subdomain === "mediator"
    //   ? setRole(MEDIATOR)
    //   : subdomain === "lender"
    //   ? setRole(LENDER)
    //   : setRole(null);

    setRole(sessionStorage.getItem("role"));
  }, []);

  return (
    <div className="w-screen max-w-full">
      {role && <Navbar />}

      <Routes>
        {
          <Route
            path="/"
            element={
              <div className="w-full h-screen">
                <Login setRole={setRole} />
              </div>
            }
          />
        }
        <Route
          path="/institution/register"
          element={
            <div className="w-full h-screen">
              <MRegister />
            </div>
          }
        />
        <Route
          path="/applicant/register"
          element={
            <div className="w-full h-screen">
              <BRegister />
            </div>
          }
        />
        <Route
          path="/admin/register"
          element={
            <div className="w-full h-screen">
              <LRegister />
            </div>
          }
        />
        {role &&
          require(`./utils/${role}.config`).default.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <PrivateRoute>
                  <div className="mt-[80px] min-h-with-nav h-with-nav">
                    <route.element />
                  </div>
                </PrivateRoute>
              }
            />
          ))}
      </Routes>
    </div>
  );
}

export default App;
