import React from "react";

const Stepper = (props) => {
  const { stepperFields, currStepper, setCurrStepper } = props;

  const handleStepperClick = (currentStepperNumber, e) => {
    // setCurrStepper(+currentStepperNumber);
  };

  return (
    <div>
      <div className="w-full flex justify-center mt-8 mb-16">
        <div className="relative flex justify-between w-[60%] after:content-[''] after:w-full after:bg-black after:h-[1px] after:absolute after:block after:top-1/2">
          {stepperFields.map((item, index) => (
            <button
              key={item.step * index}
              className="z-10 text-center text-white w-9 align-middle h-9 rounded-full bg-blue-700 flex justify-center items-center font-bold"
              onClick={(e) => handleStepperClick(item.step, e)}
            >
              <span className="">{item.step}</span>
              <span className="absolute top-10 text-black w-40">
                {item.label}
              </span>
            </button>
          ))}

          {/* <button
            className="z-10 cursor-pointer text-center text-white w-9 align-middle h-9 rounded-full bg-blue-700 flex justify-center items-center font-bold"
            onClick={(e) => handleStepperClick(2, e)}>
            <span className="">2</span>
            <span className="absolute top-10 text-black w-40">
              Bank Details
            </span>
          </button>
          <button
            className="z-10  cursor-pointer text-center text-white w-9 align-middle h-9 rounded-full bg-blue-700 flex justify-center items-center font-bold"
            onClick={(e) => handleStepperClick(3, e)}>
            <span className="">3</span>
            <span className="absolute top-10 text-black w-40">
              Financial Details
            </span>
          </button>
          <button
            className=" z-10 text-center text-white w-9 align-middle h-9 rounded-full bg-blue-700 flex justify-center items-center font-bold"
            onClick={(e) => handleStepperClick(4, e)}>
            <span className="">4</span>
            <span className="absolute top-10 text-black w-40">
              Upload Documents
            </span>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
