import axios from "axios";
import { ACCESS_TOKEN, BASE_URL } from "../../utils/constant/index";

const BORROWER_DASHBOARD_URL = "/borrowerdashboard";

// create axios instance
const newApplicationAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  },
});

export const borrowerDashboardApi = async () => {
  try {
    const response = await newApplicationAxiosInstance({
      method: "get",
      url: BORROWER_DASHBOARD_URL,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
