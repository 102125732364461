import React, { useState } from "react";
import AccordionTitle from "../../components/Accordion/AccordionTitle";
import TableBody from "../../components/Table/TableBody";
import TableContainer from "../../components/Table/TableContainer";
import TableData from "../../components/Table/TableData";
import TableHead from "../../components/Table/TableHead";
import TableRow from "../../components/Table/TableRow";
import { borrowerDashboardApi } from "../../api/borrower/borrowerDashboard.api";
import {
  borrowerLoansTableHeading,
  borrowerLoanKeynames,
} from "./newApplicationUtils";
const Status = () => {
  // const tableHeadings = [
  //   "Name",
  //   "Sanction Date \u20B9",
  //   "Loan Amount",
  //   "Status",
  //   "Return",
  // ];

  // const [tableRows, setTableRows] = useState([]);
  // const [currentData, setCurrentData] = useState([]);
  // const [prevData, setPrevData] = useState([]);
  const [borrowerLoans, setBorrowerLoans] = useState([]);

  // const keynames = [
  //   "name",
  //   "sanction_date",
  //   "loan_amt",
  //   "sanction_status",
  //   "return_status",
  // ];

  // useEffect(() => {
  //   let arr = []; // arr will hold 5 data points for each row
  //   statusData.forEach((i) => {
  //     let _obj = {};
  //     _obj.name = i.name;
  //     _obj.sanction_date = i.sanction_date;
  //     _obj.loan_amt = i.loan_amt;
  //     _obj.sanction_status = i.sanction_status;
  //     _obj.return_status = i.return_status;
  //     arr.push(_obj);
  //   });
  //   setTableRows(arr);
  // }, []);
  useState(() => {
    const getBorrowerDashboard = async () => {
      try {
        const { data } = await borrowerDashboardApi();
        let loansObj = data.map((item) => ({
          ...item,
          application_id: item.application_id.split("-")[0],
        }));
        setBorrowerLoans(loansObj);
      } catch (err) {
        console.log(err);
      }
    };
    getBorrowerDashboard();
  }, []);

  return (
    <main className="w-full max-w-[100vw] min-h-full overflow-x-auto bg-pale-blue p-8">
      {/* <h1 className="font-bold text-lg mt-4 mb-6 uppercase">
        Recent Applications
      </h1>
      <TableContainer>
        <TableHead>
          <TableRow>
            {tableHeadings.map((th, i) => (
              <TableData key={th + i}>{th}</TableData>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentData.length !== 0 &&
            currentData.map((item, index) => (
              <Accordion>
                <AccordionTitle>
                  {keynames.map((trd, i) => (
                    <>
                      <span key={item[trd] + trd + i}>{item[trd]}</span>
                    </>
                  ))}
                </AccordionTitle>
                <AccordionDetails>
                  <div className="grid grid-cols-3">
                    {Object.keys(item).map((el, i) => (
                      <div key={el + index + item.scheme_ref_no + i}>
                        <span className="font-bold text-sm">
                          {statusLabels[el]}
                        </span>{" "}
                        : <span>{item[el]}</span>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
        </TableBody>
      </TableContainer> */}
      {/* <br /> */}
      {/* <h1 className="font-bold text-lg mt-4 mb-6 uppercase">
        Previous Applications
      </h1>
      <div className="w-full ">
        <div className="w-full grid grid-flow-col grid-cols-5 justify-items-center px-4">
          {tableHeadings.map((th, i) => (
            <span
              className="font-semibold text-base mb-2 uppercase"
              key={th + i}>
              {th}
            </span>
          ))}
        </div>
        {prevData.length !== 0 &&
          prevData.map((item, index) => (
            <Accordion>
              <AccordionTitle>
                {keynames.map((trd, i) => (
                  <>
                    <span key={item[trd] + trd + i}>{item[trd]}</span>
                  </>
                ))}
              </AccordionTitle>
              <AccordionDetails>
                <div className="grid grid-cols-3">
                  {Object.keys(item).map((el, i) => (
                    <div key={el + index + item.scheme_ref_no + i}>
                      <span className="font-bold text-sm">
                        {statusLabels[el]}
                      </span>{" "}
                      : <span>{item[el]}</span>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
      </div> */}
      {/* <br /> */}
      <h1 className="font-bold text-lg mt-4 mb-6 uppercase">
        Your Application Loans
      </h1>
      <TableContainer>
        <TableHead>
          <TableRow>
            {borrowerLoansTableHeading.map((title, index) => (
              <TableData key={title + index}>{title}</TableData>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {borrowerLoans.map((item, index) => (
            <AccordionTitle key={index}>
              {borrowerLoanKeynames.map((key, i) => (
                <span key={item[key] + key + i}>{item[key]}</span>
              ))}
            </AccordionTitle>
          ))}
        </TableBody>
      </TableContainer>
    </main>
  );
};

export default Status;
