export const borrowerLoanKeynames = [
  "student_name",
  "status",
  "application_id",
];

export const borrowerLoansTableHeading = [
  "Student Name",
  "Status",
  "Application Id",
];

// function to create input values form form inputs
export const createFormInputValues = (elements) => {
  let valuesObject = elements.reduce(
    (prev, curr, index) => ({
      ...prev,
      [index + 1]: curr.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: "" }),
        {}
      ),
    }),
    {}
  );
  return valuesObject;
};

// function to convert javascript object to formData
export const convertToFormData = (payloadObject) => {
  let formDataPayload = new FormData();
  Object.entries(payloadObject).forEach(([key, value]) => {
    formDataPayload.append(key, value);
  });
  return formDataPayload;
};
