import React, { useEffect, useState } from "react";
import Accordion from "../../components/Accordion/Accordion";
import AccordionTitle from "../../components/Accordion/AccordionTitle";
import AccordionDetails from "../../components/Accordion/AccordionDetails";
import TableBody from "../../components/Table/TableBody";
import TableContainer from "../../components/Table/TableContainer";
import TableData from "../../components/Table/TableData";
import TableHead from "../../components/Table/TableHead";
import TableRow from "../../components/Table/TableRow";

import {
  getAllApplications,
  verificationToServer,
  getS3Document,
  updateApplicantDetails,
} from "../../api/lender/dashboard.api";
import Label from "../../components/FormElements/Label";
import Input from "../../components/FormElements/Input";
import Spinner from "../../components/Spinner/Spinner";
const initVerify = {
  is_verified_KYC: 0,
  is_verified_bank_statement: 0,
  is_verified_returns: 0,
  is_verified_photo: 0,
  is_verified_cheque: 0,
  is_verified_salaryslip: 0,
  is_verified_electricitybill: 0,
};

const Dashboard = () => {
  const tableHeadings = ["Student Name", "Application ID", "Loan Type"];
  const keynames = ["student_name", "appID", "loan_type"];
  const personal_details_keys = {
    aadhar_card: "Aadhar Card",
    address: "Address",
    contact1: "Contact 1",
    contact2: "Contact 2",
    current_emi: "Current EMI Amount",
    date_of_apply: "Date of Apply",
    dob: "DOB",
    email: "Email ID",
    emi_amount: "EMI Amount",
    gender: "Gender",
    grade: "Grade",
    gstin_number: "GSTIN Number",
    id_card_number: "ID Card Number",
    occupation: "Occupation",
    organization_name: "Organisation Name",
    pan_card: "PAN Card",
    parent_name: "Parent Name",
    phone_number: "Phone Number",
    reference1: "Reference 1",
    reference2: "Reference 2",
    school_fee: "School Fee",
    school_id: "School Id",
    school_name: "School Name",
    select_loan_type: "Select Loan Type",
    student_name: "Student Name",
  };
  const bank_details_keys = {
    bank_name: "Bank Name",
    branch_name: "Branch Name",
    account_number: "Account Number",
    ifsc_code: "IFSC Code",
  };

  const [loanDetails, setLoanDetails] = useState([]);
  // view 0-> personal, 1-> bank, 2-? docs in accordion details
  const [selection, setSelection] = useState({ app_id: null, view: null });
  const [loading, setLoading] = useState(true);
  const [docLoading, setDocLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [expand, setExpand] = useState();
  const [docError, setDocError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllApplications().then((res) => {
      if (res.status === 200) {
        let { data } = res;
        let newStruct = data.map((obj) => {
          let _docs = {},
            _misc = {};
          _misc = {
            appID: obj.application_id.split("-")[0],
            student_name: obj.student_name,
            loan_type: obj.select_loan_type,
            fullAppID: obj.application_id,
          };
          let _others = {};
          _others = obj;
          _docs = {
            ekyc: {
              data_uri: "",
              link: "s3_Kyc_loc",
              label: "E-KYC",
              id: "KYC",
            },
            photo: {
              data_uri: "",
              link: "s3_photo_loc",
              label: "Photo",
              id: "photo",
            },
            bank_stmnt: {
              data_uri: "",
              link: "s3_bank_statement_loc",
              label: "Bank Statement",
              id: "bank_statement",
            },
            cheque: {
              data_uri: "",
              link: "s3_cheque_loc",
              label: "Cheque",
              id: "cheque",
            },
            salary_slip: {
              data_uri: "",
              link: "s3_salaryslip_loc",
              label: "Salary Slip",
              id: "salaryslip",
            },
            itr: {
              data_uri: "",
              link: "s3_returns_loc",
              label: "IT Return",
              id: "returns",
            },
            electric_bill: {
              data_uri: "",
              link: "s3_electricitybill_loc",
              label: "Electricity Bill",
              id: "electricitybill",
            },
          };
          return {
            ..._misc,
            documents: { ..._docs },
            others: {
              ..._others,
            },
          };
        });
        setLoanDetails(newStruct);
        setLoading(false);
      }
    });
  }, []);
  const handlebankDetailsUpdate = (e, id) => {
    e.stopPropagation();

    e.preventDefault();
    if (!updateLoading) {
      setUpdateLoading(true);
      let applicantDetails = loanDetails.find((i) => i.fullAppID === id).others;
      updateApplicantDetails(applicantDetails, { id: id })
        .then((res) => {
          if (res.status === 200) {
            alert("Applicant Bank Details Updated Successfully");
            setUpdateLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Something went wrong. Please try again later.");
          setUpdateLoading(false);
        });
    }
  };
  const fetchDocuments = (id) => {
    getS3Document({ id: id })
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data[0];
          setLoanDetails((prev) =>
            prev.map((obj) => {
              if (obj.fullAppID === id) {
                return {
                  ...obj,
                  documents: {
                    bank_stmnt: {
                      ...obj["documents"]["bank_stmnt"],
                      data_uri: resData.s3_bank_statement_loc,
                    },
                    ekyc: {
                      ...obj["documents"]["ekyc"],
                      data_uri: resData.s3_Kyc_loc,
                    },
                    photo: {
                      ...obj["documents"]["photo"],
                      data_uri: resData.s3_photo_loc,
                    },
                    cheque: {
                      ...obj["documents"]["cheque"],
                      data_uri: resData.s3_cheque_loc,
                    },
                    salary_slip: {
                      ...obj["documents"]["salary_slip"],
                      data_uri: resData.s3_salaryslip_loc,
                    },
                    itr: {
                      ...obj["documents"]["itr"],
                      data_uri: resData.s3_returns_loc,
                    },
                    electric_bill: {
                      ...obj["documents"]["electric_bill"],
                      data_uri: resData.s3_electricitybill_loc,
                    },

                    // data_uri:
                    // [m]: value,
                  },
                };
              } else {
                return obj;
              }
            })
          );
          setDocLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setDocError("Something went wrong, Please try again");
        setDocLoading(false);
      });
  };

  const handleChange = (e, item) => {
    e.stopPropagation();
    let value = e.target.value;
    setLoanDetails((prev) =>
      prev.map((obj) => {
        if (obj.appID === selection.app_id) {
          return {
            ...obj,
            others: {
              ...obj["others"],
              [item]: value,
            },
          };
        } else {
          return obj;
        }
      })
    );
  };
  return (
    <main className="w-full max-w-[100vw] min-h-full overflow-x-auto bg-pale-blue p-8">
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="h-2/3 w-[100px] ml-2 l ">
            <Spinner />
          </div>
        </div>
      ) : (
        <div>
          {loanDetails.length !== 0 ? (
            <>
              <h1 className="font-semibold text-lg mt-4 mb-6 uppercase">
                Open Applications
              </h1>
              <TableContainer>
                <TableHead>
                  <TableRow>
                    {tableHeadings.map((th, i) => (
                      <TableData key={th + i}>{th}</TableData>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loanDetails.length !== 0 &&
                    loanDetails.map((li, index) => (
                      <Accordion
                        key={index + li.appID}
                        eID={li.appID}
                        expanded={expand === li.appID}
                        onClick={() => setExpand(li.appID)}
                        // className={`${
                        //   docLoading ? "cursor-none" : "cursor-pointer"
                        // }`}
                      >
                        <AccordionTitle>
                          {keynames.map((k, i) => (
                            <span key={k + i}>{li[k]}</span>
                          ))}
                        </AccordionTitle>
                        <AccordionDetails>
                          <div className={`text-center my-4`}>
                            <span
                              className={`${
                                docLoading ? "cursor-none" : "cursor-pointer"
                              } p-2 mx-4 
                            bg-[#5661eb]
                           text-white rounded-sm`}
                              onClick={(e) => {
                                if (
                                  !docLoading &&
                                  !updateLoading &&
                                  !approvalLoading
                                ) {
                                  e.stopPropagation();
                                  setSelection({ view: 0, app_id: li.appID });
                                }
                              }}
                            >
                              Personal Details
                            </span>
                            <span
                              className={`${
                                docLoading ? "cursor-none" : "cursor-pointer"
                              } p-2 mx-4  bg-[#5661eb] text-white rounded-sm`}
                              onClick={(e) => {
                                if (
                                  !docLoading &&
                                  !updateLoading &&
                                  !approvalLoading
                                ) {
                                  e.stopPropagation();
                                  setSelection({ view: 1, app_id: li.appID });
                                  setDocLoading(true);
                                  setDocError(null);
                                  fetchDocuments(li.fullAppID);
                                }
                              }}
                            >
                              Documents
                            </span>
                            <span
                              className={` ${
                                docLoading ? "cursor-none" : "cursor-pointer"
                              } p-2 mx-4  bg-[#5661eb] text-white rounded-sm`}
                              onClick={(e) => {
                                if (
                                  !docLoading &&
                                  !updateLoading &&
                                  !approvalLoading
                                ) {
                                  e.stopPropagation();
                                  setSelection({ view: 2, app_id: li.appID });
                                }
                              }}
                            >
                              Bank Details
                            </span>
                          </div>
                          {selection.view === 0 &&
                            li.appID === selection.app_id && (
                              <div className={`grid grid-cols-2 mx-32 my-5`}>
                                {Object.keys(personal_details_keys).map(
                                  (item, index) => {
                                    return (
                                      <p
                                        key={index.toString()}
                                        className={`p-1`}
                                      >
                                        {personal_details_keys[item]}:{" "}
                                        <b>{li.others[item]}</b>
                                      </p>
                                    );
                                  }
                                )}
                                <p></p>
                              </div>
                            )}
                          {selection.view === 2 &&
                            li.appID === selection.app_id && (
                              <>
                                <div className={`grid grid-cols-2 m-10 `}>
                                  {Object.keys(bank_details_keys).map(
                                    (item, index) => {
                                      return (
                                        <div className={`p-2 w-5/6`}>
                                          <Label required={true} htmlFor={item}>
                                            {bank_details_keys[item]}
                                          </Label>{" "}
                                          <Input
                                            name={item}
                                            id={item}
                                            metadata={{
                                              readOnly: false,
                                              type: "text",
                                            }}
                                            onChange={(e) => {
                                              e.stopPropagation();
                                              handleChange(e, item);
                                            }}
                                            value={li.others[item]}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div className={`flex justify-center`}>
                                  <button
                                    className={`mt-2 bg-[#4CAF50] rounded-sm p-2 text-center uppercase text-white flex items-center`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handlebankDetailsUpdate(e, li.fullAppID);
                                    }}
                                  >
                                    Update
                                    {updateLoading && (
                                      <div className="h-[20px] w-[20px] ml-2 l">
                                        <Spinner />
                                      </div>
                                    )}
                                  </button>
                                </div>
                              </>
                            )}
                          {selection.view === 1 &&
                            li.appID === selection.app_id && (
                              <div>
                                {docLoading ? (
                                  <div className=" my-10 flex flex-col justify-center items-center">
                                    <div className="h-2/3 w-[50px] ml-2 l ">
                                      <Spinner />
                                    </div>
                                    <div className="text-center">
                                      Documents are being loaded
                                    </div>
                                  </div>
                                ) : docError ? (
                                  <div className=" my-10 flex flex-col justify-center items-center">
                                    <div className="text-center">
                                      {docError}
                                    </div>
                                  </div>
                                ) : (
                                  <VerificationList
                                    docs={li.documents}
                                    id={li.fullAppID}
                                    setLoanDetails={setLoanDetails}
                                    approvalLoading={approvalLoading}
                                    setApprovalLoading={setApprovalLoading}
                                  />
                                )}
                              </div>
                            )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </TableBody>
              </TableContainer>
            </>
          ) : (
            <div className="text-center font-semibold text-lg">
              No Applications Pending
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default Dashboard;

const VerificationList = (props) => {
  const { docs, id, setLoanDetails, approvalLoading, setApprovalLoading } =
    props;

  const [docList, setDoclist] = useState({});

  useEffect(() => {
    setDoclist({});
    Object.keys(docs).forEach((key) => {
      if (docs[key].data_uri === null) {
        delete docs[key];
      }
    });
    setDoclist(docs);
  }, [docs]);

  const [verifyDocs, setVerifyDocs] = useState({ ...initVerify });
  const handleVerification = (e) => {
    let { checked, id } = e.target;
    setVerifyDocs((prev) => ({
      ...prev,
      [id]: checked ? 1 : 0,
    }));
  };

  const downloadDoc = (dataURI, file_name) => {
    const linkSource = dataURI;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    let ext = dataURI.split("/")[0].split(":")[1] === "pdf" ? "pdf" : "jpg";
    downloadLink.download = `${file_name}.` + ext;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const verifyToServer = (e, id) => {
    e.stopPropagation();
    if (!approvalLoading) {
      setApprovalLoading(true);
      try {
        verificationToServer(
          {
            ...verifyDocs,
            status: 2,
          },
          {
            id: id,
          }
        ).then((res) => {
          if (res.status === 200) {
            setLoanDetails((prev) => prev.filter((obj) => obj.appID !== id));
            alert("Verification Details Updated Successfully");
            setApprovalLoading(false);
            window.location.reload();
          }
        });
      } catch (err) {
        console.log(err);
        alert("Something went wrong. Please try again later.");
        setApprovalLoading(false);
      }
      setVerifyDocs({ ...initVerify });
      // window.scrollTo(0, 0);
    }
  };

  return (
    <div
      className="flex flex-col justify-center w-1/3 m-auto "
      onClick={(e) => e.stopPropagation()}
    >
      {Object.entries(docList).map((arr, j) => (
        <div
          className="flex justify-between w-full mb-1 items-center gap-x-2"
          key={j}
        >
          <div className="mr-2 grow">{arr[1].label}</div>

          <div
            className=" bg-blue-100 px-2 py-1 rounded-sm cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              downloadDoc(arr[1].data_uri, arr[1].label);
              // arr[1].link();
            }}
            // target="_blank"
            // rel="noreferrer"
            // href={arr[1].link}
            // download
          >
            Download
          </div>
          <input
            type={"checkbox"}
            id={`is_verified_${arr[1].id}`}
            onChange={handleVerification}
            name={arr[1].id}
            checked={Boolean(verifyDocs[`is_verified_${arr[1].id}`])}
          />
        </div>
      ))}

      {Object.keys(docList).length > 0 ? (
        <div className={`flex justify-center`}>
          <button
            className={`mt-2 bg-[#4CAF50] rounded-sm p-2 text-center uppercase text-white flex items-center`}
            onClick={(e) => verifyToServer(e, id)}
          >
            Approve
            {approvalLoading && (
              <div className="h-[20px] w-[20px] ml-2 l">
                <Spinner />
              </div>
            )}
          </button>
        </div>
      ) : (
        <div className={`flex justify-center py-4`}>
          <p>No Document Uploaded</p>
        </div>
      )}
    </div>
  );
};
