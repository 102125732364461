import React from "react";

const AccordionTitle = ({ children, onClick }) => {
  return (
    <div
      className="w-full grid grid-cols-auto-fit grid-flow-col h-20 bg-white items-center justify-items-center"
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {children && children}
    </div>
  );
};

export default AccordionTitle;
