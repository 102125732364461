import axios from "axios";
import { BASE_URL } from "../../utils/constant/index";

const REGISTER_URL = "/register";

// create axios instance
const registerUserAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {},
});

const registerStateListAxiosInstance = axios.create({
  baseURL: "https://api.sakshamtifac.org",
  headers: {},
});

// save bank detail to backend
export const registerUserApi = async (formPayload = null) => {
  try {
    const response = await registerUserAxiosInstance({
      url: REGISTER_URL,
      method: "post",
      data: formPayload,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export default registerUserApi;

//API for States and Disrticts

export const getStateListApi = async (stateData = null) => {
  try {
    const response = await registerStateListAxiosInstance({
      url: "/State",
      method: "post",
      data: stateData,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getDistrictListApi = async (state) => {
  try {
    const response = await registerStateListAxiosInstance({
      url: "/District",
      method: "post",
      data: state,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
