import React from "react";

const Error = () => {
  return (
    <div>
      <p>Page Not Found</p>
    </div>
  );
};

export default Error;
