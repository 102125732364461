import { useState } from "react";

const useModal = (value = null) => {
  const [isShowingModal, setIsShowingModal] = useState(value ? true : false);

  function toggleModal(value = null) {
    if (value !== null) {
      setIsShowingModal(value);
      return;
    }
    setIsShowingModal(!isShowingModal);
  }

  return {
    isShowingModal,
    toggleModal,
  };
};

export default useModal;
