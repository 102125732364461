import axios from "axios";
import { ACCESS_TOKEN, BASE_URL } from "../../utils/constant/index";

const PERSONAL_DETAIL_URL = "/personaldetail";

// create axios instance
const newApplicationAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  },
});

// GET
export const personalDetailApi = async (applicationId) => {
  try {
    const response = await newApplicationAxiosInstance({
      method: "get",
      url: `${PERSONAL_DETAIL_URL}?id=${applicationId}`,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

// PUT - update personal data
export const updatePersonalDetailApi = async (loanId, formPayload) => {
  try {
    const response = await newApplicationAxiosInstance({
      method: "put",
      url: `${PERSONAL_DETAIL_URL}?id=${loanId}`,
      data: formPayload,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
