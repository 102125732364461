import React, { Fragment } from "react";
import StyledLink from "../Link/Link";
import { aLinks } from "./navLinks";

const AdminNav = () => {
  return (
    <>
      {aLinks.map((link) => (
        <Fragment key={link.to}>
          <StyledLink {...link} />
        </Fragment>
      ))}
    </>
  );
};

export default AdminNav;
