import React from "react";

const Input = ({
  name,
  id,
  value,
  onChange,
  metadata: { type, required, readOnly },
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <input
      className="leading-10 indent-4 border-[1px] min-w-[100px] w-full max-w-full border-light-grey"
      type={type}
      required={required}
      name={id}
      id={id}
      onChange={handleChange}
      value={value}
      readOnly={readOnly}
    />
  );
};

export default Input;
