const borrRoutes = [
  // {
  //   path: "/",
  //   element: () => require("react-router-dom").Navigate({ to: "/login" }),
  // },
  {
    path: "/admin/login",
    element: require("../pages/Login").default,
  },
  // {
  //   path: "/admin/register",
  //   element: require("../pages/lender/Register").default,
  // },
  {
    path: "/admin/dashboard",
    element: require("../pages/lender/Dashboard").default,
  },
  {
    path: "/admin/all-institutes",
    element: require("../pages/lender/MediatorList").default,
  },
  {
    path: "*",
    element: require("../pages/Error").default,
  },
];

export default borrRoutes;
