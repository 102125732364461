import axios from "axios";
import { ACCESS_TOKEN, BASE_URL } from "../../utils/constant/index";

const STUDENT_DETAIL_URL = "/studentdetail";

// create axios instance
const studentDetailAxiosApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  },
});

export const studentDetailApi = async (mediatorId) => {
  try {
    const response = await studentDetailAxiosApi({
      method: "get",
      url: `${STUDENT_DETAIL_URL}?id=${mediatorId}`,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
