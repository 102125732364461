import axios from "axios";
import { BASE_URL } from "../../utils/constant";
import { getToken } from "../../utils/sessionUtils";

export const sendExcelToServer = async (payload) => {
  try {
    let response = await axios.post(`${BASE_URL}/populatestudent`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};
