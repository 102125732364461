import axios from "axios";
import { ACCESS_TOKEN, BASE_URL } from "../../utils/constant/index";

const BANK_DETAIL_URL = "/application";

// create axios instance
const newApplicationAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  },
});

// save bank detail to backend
export const postNewApplicationApi = async (formPayload = null) => {
  try {
    const response = await newApplicationAxiosInstance({
      url: BANK_DETAIL_URL,
      method: "post",
      data: formPayload,
      // headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (err) {
    throw err;
  }
};
