import axios from "axios";
import { BASE_URL } from "../../utils/constant";
import { getToken } from "../../utils/sessionUtils";

export const getAllApplications = async () => {
  try {
    let res = await axios.get(`${BASE_URL}/lenderdashboard`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const verificationToServer = async (payload, params) => {
  try {
    let res = await axios.put(`${BASE_URL}/lenderverification`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: params,
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getS3Document = async (params) => {
  try {
    let res = await axios.get(`${BASE_URL}/getdocument`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: params,
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateApplicantDetails = async (payload, params) => {
  try {
    let res = await axios.put(`${BASE_URL}/lenderdashboard`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params,
    });
    return res;
  } catch (err) {
    throw err;
  }
};
