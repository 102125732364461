export const BORROWER = "borrower";
export const LENDER = "lender";
export const MEDIATOR = "mediator";

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const ACCESS_TOKEN = "ACCESS_TOKEN";

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const LOGIN_TIME = "LOGIN_TIME";

export const USER = "user";
