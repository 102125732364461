import React, { memo, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import CreateForm from "../../components/DynamicForm/CreateForm";
import formJson from "../../utils/formJSON/newApplicationForm.json";
import SelectLoan from "./SelectLoan";

import useModal from "../../hooks/useModal";
import Stepper from "../../components/Stepper/Stepper";
import {
  createFormInputValues,
  convertToFormData,
} from "./newApplicationUtils";
import { postNewApplicationApi } from "../../api/newApplication/newApplication.api";
import {
  personalDetailApi,
  updatePersonalDetailApi,
} from "../../api/personal/personalDetail.api";
import Spinner from "../../components/Spinner/Spinner";

const NewApplication = () => {
  const navigate = useNavigate();
  const [currStepper, setCurrStepper] = useState(1);
  const [stepperFields, setStepperFields] = useState([]);

  const { isShowingModal, toggleModal } = useModal(false);
  const [feedbackText, setFeedbackText] = useState("");

  const [personalData, setPersonalData] = useState(null);
  const [isApplicationidSelected, setIsApplicationIdSelected] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState("");

  const [formArr, setFormArr] = useState([]);
  const [formInputValues, setFormInputValues] = useState({});
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(false);

  // set form input elements array
  useEffect(() => {
    // set stepper form input elements
    let stepperFormElements = [];
    Object.values(formJson).forEach((item) => {
      stepperFormElements.push(item.form);
    });
    if (currStepper === 1) {
      stepperFormElements[currStepper - 1].forEach((item) => {
        item.metadata.readOnly = true;
      });
    }
    setFormArr(stepperFormElements);
  }, []);

  // update input field values
  useEffect(() => {
    let formVal = createFormInputValues(formArr);
    // prefill with personal data
    if (personalData && currStepper === 1) {
      formVal[currStepper] = { ...personalData };
    }
    setFormInputValues(formVal);
  }, [formArr, personalData]);

  useEffect(() => {
    const getPersonalDetail = async () => {
      try {
        const { data } = await personalDetailApi(selectedApplicationId);
        setPersonalData(data);
      } catch (err) {
        console.log(err);
      }
    };
    if (selectedApplicationId.length > 0) {
      getPersonalDetail();
    }
  }, [selectedApplicationId]);

  // toggle form input to readOnly attribute
  useEffect(() => {
    if (formArr.length && currStepper === 1) {
      let stepperFormElements = formArr;
      stepperFormElements[currStepper - 1].forEach((item) => {
        if (item.type === "select") {
          item.metadata.readOnly = true;
        } else {
          item.metadata.readOnly = !isFormEditable;
        }
      });
      setFormArr((prev) => [...prev, ...stepperFormElements]);
    }
  }, [isFormEditable]);

  const formValidate = () => {
    let inValid = Object.entries(formInputValues[currStepper]).some((inp) => {
      let obj = Object.values(formArr[currStepper - 1]).find(
        (o) => o.id === inp[0]
      );
      return (
        obj?.metadata?.required &&
        (inp[1] === "" || inp[1] === null || inp[1] === undefined)
      );
    });
    return !inValid;
  };

  const handleProceedButtonClick = (event, data = null) => {
    if (formValidate() && currStepper < stepperFields.length) {
      window.scrollTo(0, 0);
      setCurrStepper((prev) => +(parseInt(prev) + 1));
    } else alert("Please fill in the missing details");
  };

  const handleFormSubmitButtonClick = (event, data = null) => {
    event.preventDefault();
    if (!isSubmitButtonClicked) {
      setIsSubmitButtonClicked(true);
      handleFormSubmit(formInputValues);
    }
    // setIsSubmitButtonClicked(true);
  };

  // stepper field
  useState(() => {
    const stepperObj = Object.keys(formJson).map((key) => ({
      step: key,
      label: formJson[key].title,
    }));
    setStepperFields(stepperObj);
  }, []);

  const handleApplicationIdSubmit = (data) => {
    setSelectedApplicationId(data);
    setIsApplicationIdSelected(true);
  };

  const handleFormSubmit = async (formData = {}) => {
    if (!formValidate()) {
      alert("Fill in the missing details");
      return;
    }
    const personalFormPayload = formData["1"];
    // update personal data
    try {
      // TODO: check response status and alert
      const { data } = await updatePersonalDetailApi(
        selectedApplicationId,
        personalFormPayload
      );
    } catch (err) {
      console.log(err);
    }

    // // post application
    const restFormPayload = {
      ...formData["2"],
      ...formData["3"],
      id: selectedApplicationId,
      current_emi: personalFormPayload.current_emi,
    };

    try {
      const formDataPayload = convertToFormData(restFormPayload);
      console.table([...formDataPayload]);
      const { data } = await postNewApplicationApi(formDataPayload);
      console.log("post new application success", data);
      toggleModal(true);
      alert("New application created successfully");
      setIsSubmitButtonClicked(false);
      navigate("/applicant/status");
      setFeedbackText("New application created successfully");
    } catch (err) {
      toggleModal(true);
      alert("Error while creating new application");
      setIsSubmitButtonClicked(false);

      setFeedbackText("Error while creating new application");
      console.log("post application error", err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isApplicationidSelected]);

  // render card to choose application id before application form
  if (!isApplicationidSelected) {
    return <SelectLoan handleApplicationIdSubmit={handleApplicationIdSubmit} />;
  }

  return (
    <div className={`bg-pale-blue pt-8 pb-32 relative`}>
      {isSubmitButtonClicked && (
        <div
          className={`fixed top-0 right-0 left-0 bottom-0 w-[100%] h-[100%]  z-[2] bg-[rgba(0,0,0,0.5)] flex items-center justify-center`}
        >
          <div className="h-[100px] w-[100px] ml-2 l">
            <Spinner />
          </div>
        </div>
      )}
      <Stepper
        currStepper={+currStepper}
        setCurrStepper={setCurrStepper}
        stepperFields={stepperFields}
      />
      {/* {isShowingModal && (
        <div className="absolute z-50 w-full h-full flex justify-center">
          <FormSubmitModal onClose={toggleModal} feedbackText={feedbackText} />
        </div>
      )} */}
      <form>
        <CreateForm
          formElements={formArr}
          formInputValues={formInputValues}
          setFormInputValues={setFormInputValues}
          personalData={personalData}
          currStepper={currStepper}
          isFormEditable={isFormEditable}
          isSubmitButtonClicked={isSubmitButtonClicked}
          handleFormSubmit={handleFormSubmit}
        />
        <div className="flex justify-center gap-x-5 my-12">
          {currStepper === 1 && (
            <button
              type="button"
              className="w-44 px-8 py-4 bg-green-500 rounded-full font-semibold"
              onClick={() => {
                setIsFormEditable(true);
              }}
            >
              {"EDIT"}
            </button>
          )}
          <button
            type={"button"}
            className=" w-44 px-8 py-4 bg-green-500 rounded-full font-semibold"
            onClick={
              currStepper < stepperFields.length
                ? handleProceedButtonClick
                : handleFormSubmitButtonClick
            }
          >
            {currStepper < stepperFields.length ? "PROCEED" : "SUBMIT"}
          </button>
        </div>{" "}
      </form>
    </div>
  );
};

export default memo(NewApplication);
