import React, { useContext } from "react";
import { ExpandAcc } from "./Accordion";

const AccordionDetails = ({ children }) => {
  const x = useContext(ExpandAcc);

  return (
    <div
      className={`min-h-0 h-auto transition-[max-height] ease-in bg-white overflow-hidden ${
        x ? "max-h-[800px] delay-100 duration-300" : "max-h-0 duration-100"
      }`}
    >
      {children && children}
    </div>
  );
};

export default AccordionDetails;
