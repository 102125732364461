import React, { useState, useEffect } from "react";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import signIn from "../../assets/images/730_generated-removebg-preview.png";
import {
  getDistrictListApi,
  getStateListApi,
  registerUserApi,
} from "../../api/register/register.api";
import {
  mediatorRegisterFormFields,
  // getRegisterFormPayload,
} from "./mediatorUtils";
import Select from "react-select";
// import { BORROWER, MEDIATOR, LENDER } from "../constant/index";
import logoHorseSrc from "../../assets/images/thar_logo_1.png";
const Register = () => {
  const [formData, setFormData] = useState(mediatorRegisterFormFields);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const navigate = useNavigate();

  //for state and distric names
  useEffect(() => {
    getStateListApi().then((res) => setStateList(res.data));
  }, []);

  const handleSelect = (e, type) => {
    setDistrictList([]);
    if (type === "state") {
      let currentData = { ...formData };
      currentData["state_select"].value = e.value;
      setFormData(currentData);
      const request = {
        level: 2,
        thislevel: e.value,
        locale: "en",
      };

      getDistrictListApi(request).then((res) => setDistrictList(res.data));
    } else if (type === "district") {
      let currentData = { ...formData };
      currentData["district_select"].value = e.value;
      setFormData(currentData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password.value === formData.confirmPassword.value) {
      try {
        let finalData = {};
        Object.entries(formData).forEach(
          (c) => (finalData[c[1].payloadID] = c[1].value)
        );
        finalData.account_type = 2;
        finalData.mediator_id = 0;

        delete finalData.confirm_password;
        if (
          Object.values(finalData).includes(null) ||
          Object.values(finalData).includes("")
        ) {
          alert("Please fill all the fields");
        } else {
          const response = await registerUserApi(finalData);
          if (response.status === 200) {
            alert("User Registered Successfully");
            navigate("/");
          }
        }
      } catch (err) {
        console.log(err.message);
        alert("Something went wrong. Please try again later");
      }
    } else {
      alert("password does not match");
    }
    // api call for login
    // navigate("/register");
  };

  const handleInputChange = (e) => {
    // let { name, value } = e.target;

    const name = e.target.name;
    const value = e.target.value;
    let currentData = { ...formData };
    currentData[name].value = value;
    setFormData(currentData);
  };

  return (
    <div className="w-full relative flex items-center justify-center">
      <div className="w-1/2 h-screen bg-indigo-500 flex items-center justify-center self-start sticky top-0">
        <img src={signIn} alt="" className="h-" />
      </div>
      <div className="w-1/2 h-full flex flex-col items-center justify-center mt-8 mb-8">
        <div className="w-[320px] h-[70px]  p-1">
          <img src={logoHorseSrc} alt="logo_image" className="w-full h-full" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <InputElement
              inputKey={"email"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <InputElement
              inputKey={"password"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <InputElement
              inputKey={"confirmPassword"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-4">
            <InputElement
              inputKey={"contact_number"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <InputElement
              inputKey={"schoolName"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <InputElement
              inputKey={"designation"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <InputElement
              inputKey={"schoolAddress"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-4">
            <OptionSelect
              inputKey={"state_select"}
              formData={formData}
              optionData={stateList}
              onChange={(e) => handleSelect(e, "state")}
            />
          </div>
          <div className="mb-4">
            <OptionSelect
              inputKey={"district_select"}
              formData={formData}
              optionData={districtList}
              onChange={(e) => handleSelect(e, "district")}
            />
          </div>
          <br />
          <div className="w-full text-center">
            <button
              className="py-2 px-4 uppercase text-sm bg-blue-300 rounded-md"
              type="submit"
            >
              REGISTER
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

function InputElement(props) {
  const { inputKey, children, formData, ...rest } = props;
  if (!formData[inputKey]) {
    return null;
  }

  const { label, name, value, type } = formData[inputKey];
  return (
    <>
      <InputLabel labelText={label} name={name} />
      <Input
        placeholder={`${label}...`}
        value={value}
        type={type}
        id={name}
        name={name}
        {...rest}
      />
    </>
  );
}

function OptionSelect(props) {
  const { inputKey, children, formData, optionData, ...rest } = props;
  if (!formData[inputKey]) {
    return null;
  }
  const { label, name, placeholder } = formData[inputKey];
  return (
    <>
      <InputLabel labelText={label} name={name} />
      <Select
        menuPlacement="top"
        placeholder={placeholder}
        options={optionData}
        id={name}
        name={name}
        {...rest}
      />
    </>
  );
}

const Input = (props) => {
  const { value, name, type, onChange, placeholder, ...rest } = props;

  return (
    <>
      <input
        className="leading-10 indent-4 border-[1px] min-w-[150px] w-full max-w-full border-light-grey outline-none"
        type={type}
        value={value}
        onChange={onChange}
        {...rest}
        placeholder={placeholder}
        name={name}
      />
    </>
  );
};

function InputLabel(props) {
  const { labelText, name } = props;
  return (
    <label htmlFor={name} className="font-medium mb-1 text-base text-gray-500">
      {labelText}
    </label>
  );
}

export default Register;
