import axios from "axios";
import { BASE_URL, ACCESS_TOKEN, REFRESH_TOKEN, LOGIN_TIME } from "./constant";
export const getToken = () => {
  if (localStorage.getItem("ACCESS_TOKEN")) {
    let started = localStorage.getItem(LOGIN_TIME);
    let diff = Date.now() - started;

    if (diff >= 1000 * 60 * 40) {
      let refreshToken = localStorage.getItem("REFRESH_TOKEN");
      axios
        .post(`${BASE_URL}/api/token/refresh/`, {
          refresh: refreshToken,
        })
        .then((response) => {
          localStorage.setItem(ACCESS_TOKEN, response.data.token.access);
          localStorage.setItem(LOGIN_TIME, Date.now());
        })
        .catch((error) => {
          console.log("Something went wrong. Please try again later.");
        });

      return localStorage.getItem("ACCESS_TOKEN") || null;
    } else {
      return localStorage.getItem("ACCESS_TOKEN") || null;
    }
  } else {
    return;
  }
};
