import React, { Fragment } from "react";
import xlFile from "../../utils/files/School_Template.xlsx";
import StyledLink from "../Link/Link";
import { mLinks } from "./navLinks";

const MedNav = () => {
  return (
    <>
      <div className="grid grid-rows-1 grid-flow-col">
        {mLinks.map((link) => (
          <Fragment key={link.to}>
            <StyledLink {...link} />
          </Fragment>
        ))}
        <a
          className="font-medium whitespace-pre-line w-[100px] block leading-4 text-center"
          href={xlFile}
          download="School_Template.xlsx"
        >
          Download Template
        </a>
      </div>
    </>
  );
};

export default MedNav;
