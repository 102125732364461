import React, { Fragment } from "react";
import StyledLink from "../Link/Link";
import { bLinks } from "./navLinks";

const BorrowerNav = () => {
  return (
    <>
      {bLinks.map((link) => (
        <Fragment key={link.to}>
          <StyledLink {...link} />
        </Fragment>
      ))}
    </>
  );
};

export default BorrowerNav;
