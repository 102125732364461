import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import registerUserApi from "../../api/register/register.api";
import signIn from "../../assets/images/730_generated-removebg-preview.png";
// import { BORROWER, MEDIATOR, LENDER } from "../constant/index";
import logoHorseSrc from "../../assets/images/thar_logo_1.png";

const lenderRegisterFormField = {
  email: {
    type: "email",
    label: "Email",
    name: "email",
    value: "",
    error: "",
  },
  password: {
    type: "password",
    label: "Password",
    name: "password",
    value: "",
    error: "",
  },
  // userType: {
  //   element: [
  //     {
  //       label: "Super Admin",
  //       val: "is_super_admin",
  //       name: "userType",
  //       type: "radio",
  //     },
  //     {
  //       label: "Admin",
  //       val: "is_admin",
  //       name: "userType",
  //       type: "radio",
  //     },
  //     {
  //       label: "Accountant",
  //       val: "is_accountant",
  //       name: "userType",
  //       type: "radio",
  //     },
  //   ],
  //   name: "userType",
  //   value: {
  //     is_admin: false,
  //     is_accountant: false,
  //     is_super_admin: false,
  //   },
  //   error: "",
  // },
};

const Register = () => {
  const [formData, setFormData] = useState(lenderRegisterFormField);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    let finalData = {};
    try {
      Object.values(formData).forEach((e) => {
        console.log(e);
        if (typeof e.value === "object") {
          finalData = { ...finalData, ...e.value };
        } else if (typeof e.value === "string") {
          let { name, value } = e;
          finalData[name] = value;
        }
      });
      finalData.account_type = 1;
      finalData.is_admin = 1;
      finalData.is_accountant = 0;
      finalData.is_superadmin = 0;

      if (
        Object.values(finalData).includes(null) ||
        Object.values(finalData).includes("")
      ) {
        alert("Please fill all the fields");
      } else {
        let response = await registerUserApi(finalData);
        if (response.status === 200) {
          alert("User registered successfully");
        } else {
          alert("Something went wrong. Please try again later");
        }
      }
    } catch (e) {
      console.log(e);
      alert("Something went wrong");
    }
    // navigate("/register");
  };

  const handleInputChange = (e) => {
    // let { name, value } = e.target;

    const name = e.target.name;
    const value = e.target.value;
    let currentData = { ...formData };

    // handle user type option
    if (name === "userType") {
      // reset user type to false and update current selected user to true
      let userTypeOptions = Object.keys(formData.userType.value).reduce(
        (prev, curr) => ({ ...prev, [curr]: false }),
        {}
      );
      currentData[name].value = { ...userTypeOptions, [value]: true };
    } else {
      currentData[name].value = value;
    }
    setFormData(currentData);
  };

  return (
    <div className="w-full relative flex items-center justify-center">
      <div className="w-1/2 h-screen bg-indigo-500 flex items-center justify-center self-start sticky top-0">
        <img src={signIn} alt="" className="h-" />
      </div>
      <div className="w-1/2 h-full flex flex-col items-center justify-center mt-12 mb-12">
        <div className="w-[320px] h-[70px]  p-1">
          <img src={logoHorseSrc} alt="logo_image" className="w-full h-full" />
        </div>
        <form onSubmit={handleSubmit}>
          {/* <div className="mb-4">
            <InputElement
              inputKey={"userName"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div> */}
          <div className="mb-4">
            <InputElement
              inputKey={"email"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <InputElement
              inputKey={"password"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <InputElement
              inputKey={"mobileNumber"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          {/* <div>
            {formData.userType.element.map((item, index) => (
              <div
                key={index}
                className="flex flex-row gap-2 items-center justify-start"
              >
                <input
                  className="h-4 w-4"
                  type={item.type}
                  name={item.name}
                  value={item.val}
                  onChange={handleInputChange}
                />
                <InputLabel labelText={item.label} />
              </div>
            ))}
          </div> */}

          <br />
          <div className="w-full text-center">
            <button
              className="py-2 px-4 uppercase text-sm bg-blue-300 rounded-md"
              type="submit"
            >
              REGISTER
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

function InputElement(props) {
  const { inputKey, children, formData, ...rest } = props;
  if (!formData[inputKey]) {
    return null;
  }

  const { label, name, value, type } = formData[inputKey];
  return (
    <>
      <InputLabel labelText={label} name={name} />
      <Input
        placeholder={`${label}...`}
        value={value}
        type={type}
        id={name}
        name={name}
        {...rest}
      />
    </>
  );
}

const Input = (props) => {
  const { value, name, type, onChange, placeholder, ...rest } = props;

  return (
    <>
      <input
        className="leading-10 indent-4 border-[1px] min-w-[230px] w-full max-w-full border-light-grey outline-none"
        type={type}
        value={value}
        onChange={onChange}
        {...rest}
        placeholder={placeholder}
        name={name}
      />
    </>
  );
};

function InputLabel(props) {
  const { labelText, name } = props;
  return (
    <label htmlFor={name} className="font-medium mb-1 text-base text-gray-500">
      {labelText}
    </label>
  );
}

export default Register;
