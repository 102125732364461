import axios from "axios";
import { BASE_URL } from "../../utils/constant";
import { getToken } from "../../utils/sessionUtils";

export const getAllInstitutes = async () => {
  try {
    return await axios.get(`${BASE_URL}/mediatordetail`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  } catch (err) {
    throw err;
  }
};
