const borrRoutes = [
  // {
  //   path: "/",
  //   element: () => require("react-router-dom").Navigate({ to: "/login" }),
  // },
  {
    path: "/applicant/login",
    element: require("../pages/Login").default,
  },
  // {
  //   path: "/applicant/register",
  //   element: require("../pages/borrower/Register").default,
  // },
  {
    path: "/applicant/home",
    element: require("../pages/borrower/Status").default,
  },
  {
    path: "/applicant/new-application",
    element: require("../pages/borrower/NewApplication").default,
  },
  {
    path: "*",
    element: require("../pages/Error").default,
  },
];

export default borrRoutes;
