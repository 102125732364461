import React from "react";
import { default as ReactSelect } from "react-select";

const createSelectOptions = (options, values = null) => {
  return options.reduce(
    (prev, curr, currIndex) => [
      ...prev,
      { value: values ? values[currIndex] : curr, label: curr },
    ],
    []
  );
};

const Select = ({ name, value, onChange, metadata }) => {
  // logic to convert options arr to an arr of objects
  let selectOptions = [];
  if (metadata.options) {
    selectOptions = createSelectOptions(metadata.options, metadata?.values);
  }

  const handleChange = (event) => {
    // set property to event manually for React Select to handle controlled component
    event.target = {
      name: name,
      value: event.value,
    };

    if (onChange) {
      onChange(event);
    }
  };
  return (
    <ReactSelect
      onChange={handleChange}
      options={selectOptions}
      value={{ value: value, label: value === "EDU" ? "Education" : value }}
      isDisabled={metadata.readOnly}
    />
  );
};

export default Select;
