import React, { useEffect, useRef, useState } from "react";
import { sendExcelToServer } from "../../api/mediator/excelUpload.api";
import { USER } from "../../utils/constant";
import { studentDetailApi } from "../../api/mediator/studentDetail.api";
import { studentDetailColumns } from "./mediatorUtils";

const Home = () => {
  const inpFileRef = useRef(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [students, setStudents] = useState([]);

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const { med_id } = currentUser;

  useEffect(() => {
    const getStudentDetails = async () => {
      try {
        const { data } = await studentDetailApi(med_id);
        setStudents(data);
      } catch (err) {
        console.log(err);
      }
    };
    getStudentDetails();
  }, [med_id]);

  const handleFileSelection = (e) => {
    setFileInfo(e.target.files[0]);
  };

  const sumbitFile = (e) => {
    let formData = new FormData();
    formData.append("excel_file", fileInfo);
    formData.append("id", JSON.parse(localStorage.getItem(USER)).med_id);
    fileInfo !== {} && fileInfo !== null
      ? sendExcelToServer(formData)
          .then((res) => {
            if (res.status === 200) {
              alert("Data has been uploaded successfully");
              setFileInfo(null);
            }
          })
          .catch((e) => {
            console.log(e);
            alert("Something went wrong. Please try again later");
          })
      : alert("Please select a file");
  };

  const getStatusString = (val) => {
    switch (val) {
      case 0:
        return "Not Applied";
      case 1:
        return "Submitted";
      case 2:
        return "Verified";
      case 3:
        return "Hold";
      case 4:
        return "Rejected";
      case 5:
        return "Approved";
      case 6:
        return "Sanctioned";
      default:
        return "-";
    }
  };

  const getAppliedString = (val) => {
    switch (val) {
      case 0:
        return "Not Registered";
      case 1:
        return "Registered";

      default:
        return "-";
    }
  };

  return (
    <>
      <div className="h-auto flex flex-row justify-center items-center">
        {/* <h3 className="font-semibold text-lg">
          Welcome Back {JSON.parse(localStorage.getItem(USER)).name}!
        </h3> */}
        <div className="m-5 mt-6 text-center">
          {fileInfo ? (
            <div className="mb-4 text-base">
              <span className="font-medium">{fileInfo.name}</span>{" "}
              <span>is ready to upload</span>
              <></>
            </div>
          ) : (
            <>
              <div className="mb-4 text-base font-medium">
                Upload Student Details
              </div>
              <input
                className="invisible hidden"
                type="file"
                name="excel"
                onChange={handleFileSelection}
                ref={inpFileRef}
                accept=".xlsx,.xls"
              />
              <button
                onClick={() => inpFileRef.current.click()}
                className="w-auto px-4 py-2 text-base bg-blue-400 rounded-md font-normal hover:drop-shadow-xl text-white"
              >
                Select File
              </button>
            </>
          )}
          <div>
            {fileInfo && (
              <button
                className="w-[150px] px-2 py-2 text-base bg-red-500 rounded-md font-normal hover:drop-shadow-xl uppercase mr-2 text-white"
                onClick={() => setFileInfo(null)}
              >
                Remove
              </button>
            )}
            {fileInfo && (
              <button
                onClick={sumbitFile}
                className="w-[150px] px-2 py-2 text-base bg-green-500 rounded-md font-normal hover:drop-shadow-xl uppercase text-white"
              >
                Upload
              </button>
            )}
          </div>
        </div>
      </div>
      {/* render student details */}
      <div>
        {/* <div className="py-2 pt-4 flex items-center justify-center">
          <h4 className="text-base font-medium text-center">Student Details</h4>
        </div> */}
        <div className="p-4">
          {students.length > 0 && (
            <div className="w-full overflow-x-auto">
              <div className="flex flex-row flex-nowrap ">
                {/* {Object.keys(students[0]).map((key, index) => ( */}
                {/* {Object.keys(studentDetailColumns).map((key, index) => ( */}
                {studentDetailColumns.map((key, index) => (
                  <div
                    className="w-44 shrink-0  py-4  bg-slate-300"
                    key={`${key}+index`}
                  >
                    <span className="flex justify-center items-center text-base font-medium capitalize">
                      {/* {studentDetailColumns[`${key}`]?.label || ""} */}
                      {/* {studentDetailColumns[`${key}`]?.label || ""} */}
                      {key.label || ""}
                    </span>
                  </div>
                ))}
              </div>
              <div className="">
                {students?.map((studentObj, index) => (
                  <div key={index} className="flex flex-row flex-nowrap ">
                    {studentDetailColumns.map(({ key }, index) => (
                      <div
                        className="w-44 shrink-0  flex justify-center items-center  py-3 border-b-[1px] border-slate-300"
                        key={`${key}+${index}`}
                      >
                        <span className="text-sm leading-[17px]">
                          {key === "status"
                            ? getStatusString(studentObj[key])
                            : key === "is_applied"
                            ? getAppliedString(studentObj[key])
                            : studentObj[key]}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
