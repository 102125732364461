import React, { createContext, useState } from "react";

export const ExpandAcc = createContext();
const Accordion = ({ children, expanded, onClick, eID }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // useEffect(() => console.log({ expanded, isExpanded, eID }));

  return (
    <ExpandAcc.Provider value={isExpanded ? expanded : false}>
      <section
        className="border-[1px] border-light-grey mb-4 last:mb-0 rounded-[10px] overflow-hidden p-4 bg-white"
        onClick={() => {
          //  logic for uncontrolled accordion
          // if (expanded === false) {
          //   setIsExpanded(!isExpanded);
          // }
          // onClick();

          // logic for controlled accordion
          if (!expanded) {
            setIsExpanded(true);
            onClick();
          } else {
            setIsExpanded(false);
          }
        }}
      >
        {children && children}
      </section>
    </ExpandAcc.Provider>
  );
};

export default Accordion;
