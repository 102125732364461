import React, { Fragment, useEffect, useState } from "react";
import { getAllInstitutes } from "../../api/lender/mediatorInfo.api";

const MediatorList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getAllInstitutes()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((e) => {
        alert("Something went wrong. Please Try again later");
      });
  }, []);
  return (
    <div className="h-with-nav">
      {/* table wrapper */}
      <div className="overflow-auto h-full py-4">
        <table className="grid grid-cols-1 w-auto grid-flow-row m-2">
          <thead>
            <tr className="grid grid-rows-1 grid-flow-col auto-cols-fr py-3">
              <th>School Name</th>
              <th>School Address</th>
              <th>School District</th>
              <th>State</th>
              <th>Contact No.</th>
              <th>Bank Name</th>
              <th>Bank IFSC</th>
              <th>Bank Account Number</th>
            </tr>
          </thead>
          <tbody>
            {data.map((td) => (
              <Fragment key={td.school_id}>
                <tr className="grid grid-rows-1  grid-flow-col auto-cols-fr py-3 border-b-[1px] border-slate-300">
                  <td className="text-center">
                    {td.school_name === null ||
                    td.school_name === undefined ||
                    td.school_name === ""
                      ? "-"
                      : td.school_name}
                  </td>
                  <td className="text-center">
                    {td.school_address === null ||
                    td.school_address === undefined ||
                    td.school_address === ""
                      ? "-"
                      : td.school_address}
                  </td>
                  <td className="text-center">
                    {td.district === null ||
                    td.district === undefined ||
                    td.district === ""
                      ? "-"
                      : td.district}
                  </td>
                  <td className="text-center">
                    {td.state === null ||
                    td.state === undefined ||
                    td.state === ""
                      ? "-"
                      : td.state}
                  </td>
                  <td className="text-center">
                    {td.contact_number === null ||
                    td.contact_number === undefined ||
                    td.contact_number === ""
                      ? "-"
                      : td.contact_number}
                  </td>
                  <td className="text-center">
                    {td.bank_name === null ||
                    td.bank_name === undefined ||
                    td.bank_name === ""
                      ? "-"
                      : td.bank_name}
                  </td>
                  <td className="text-center">
                    {td.bank_ifsc === null ||
                    td.bank_ifsc === undefined ||
                    td.bank_ifsc === ""
                      ? "-"
                      : td.bank_ifsc}
                  </td>
                  <td className="text-center">
                    {td.bank_acc_no === null ||
                    td.bank_acc_no === undefined ||
                    td.bank_acc_no === ""
                      ? "-"
                      : td.bank_acc_no}
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MediatorList;
