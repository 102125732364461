import React, { useState } from "react";
import { borrowerDashboardApi } from "../../api/borrower/borrowerDashboard.api";

const NOT_AVAILABLE = "na";
const APPLICATION_ID = "application_id";

const SelectLoan = (props) => {
  const [applicationId, setApplicationId] = useState("");
  const [applicationIdArray, setApplicationIdArray] = useState([]);
  const { handleApplicationIdSubmit } = props;
  const handleChange = (event) => {
    const { type, name, value } = event.target;
    setApplicationId(value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (applicationId.length > 0) {
      handleApplicationIdSubmit(applicationId);
    }
  };
  useState(() => {
    const getBorrowerDashboard = async () => {
      try {
        const { data } = await borrowerDashboardApi();
        setApplicationIdArray(data);
      } catch (err) {
        console.log(err);
      }
    };
    getBorrowerDashboard();
  }, []);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="bg-slate-100 p-12 drop-shadow-lg">
        <h1 className="text-xl font-semibold mb-6 text-center">Select Loan</h1>
        <form onSubmit={handleFormSubmit}>
          {applicationIdArray?.map((item, index) => (
            <div key={index} className="flex flex-row items-center gap-2 mb-4">
              <input
                type="radio"
                className="h-4 w-4"
                id={item[APPLICATION_ID]}
                name={APPLICATION_ID}
                onChange={handleChange}
                value={item[APPLICATION_ID]}
                checked={item[APPLICATION_ID] === applicationId}
                disabled={item.status.toLocaleLowerCase() !== NOT_AVAILABLE}
              />
              <label htmlFor={item[APPLICATION_ID]}>{item.student_name}</label>
            </div>
          ))}
          <button
            type="submit"
            className="py-4 px-12 bg-green-500 font-medium text-lg mt-8"
          >
            New Application
          </button>
        </form>
      </div>
    </div>
  );
};

export default SelectLoan;
