import React from "react";
import { Link } from "react-router-dom";
import lStyle from "./Link.module.css";

const StyledLink = ({ component, to, name, children, ...rest }) => {
  return (
    <Link className={`${lStyle.link} p-2`} {...rest} to={to}>
      {name}
      {children && children}
    </Link>
  );
};

export default StyledLink;
