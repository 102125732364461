import React, { memo } from "react";
import {
  DATE,
  FILE,
  NUMBER,
  RADIO,
  SELECT,
  SHORTTEXT,
} from "../../utils/constant/inputTypes";
import Input from "../FormElements/Input";
import Label from "../FormElements/Label";
import Select from "../FormElements/Select";

const CreateForm = (props) => {
  const { formElements, formInputValues, setFormInputValues, currStepper } =
    props;

  // function to update state on input change
  const onChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    const currInputs = formInputValues[`${currStepper}`];
    if (event.target.type === "file") {
      currInputs[name] = event.target.files[0];
    } else {
      currInputs[name] = value;
    }

    setFormInputValues((prev) => ({
      ...prev,
      [currStepper]: { ...currInputs },
    }));
    // state to be updated in foll format
    /*
  {
    1:{
      name:"test",
      gender:"m"    
    },
    2:{
      bank:"123"
    },
    3:{
        finance:"random"
    },
    4:{
        f1:"file"
    }
  }
 */
  };

  const renderForm = (formField, inputValues) => {
    let { id, name, type, metadata } = formField;

    switch (type) {
      case SHORTTEXT:
        return (
          <>
            <Label required={metadata.required} htmlFor={id}>
              {name}
            </Label>
            <Input
              name={id}
              id={id}
              metadata={{ ...metadata, type: "text" }}
              onChange={onChange}
              value={inputValues?.[id]}
            />
          </>
        );

      case NUMBER:
        return (
          <>
            <Label required={metadata.required} htmlFor={id}>
              {name}
            </Label>
            <Input
              name={id}
              id={id}
              metadata={{ ...metadata, type: "number" }}
              onChange={onChange}
              value={inputValues?.[id]}
            />
          </>
        );

      case SELECT:
        return (
          <>
            <Label required={metadata.required} htmlFor={id}>
              {name}
            </Label>
            <Select
              onChange={onChange}
              metadata={{ ...metadata }}
              name={id}
              value={inputValues?.[id]}
            />
          </>
        );
      case DATE:
        return (
          <>
            <Label required={metadata.required} htmlFor={id}>
              {name}
            </Label>
            <input
              className="leading-10 indent-4 border-[1px] min-w-[100px] w-full max-w-full border-light-grey"
              type="date"
              onChange={onChange}
              name={id}
              id={id}
              value={inputValues?.[id]}
            />
          </>
        );
      case RADIO:
        return (
          <>
            <Label required={metadata.required} htmlFor={id}>
              {name}
            </Label>
          </>
        );
      case FILE:
        return (
          <>
            <Label required={metadata.required} htmlFor={id}>
              {name}
            </Label>
            <input
              type="file"
              // value={inputValues?.[id]}
              name={id}
              onChange={onChange}
              accept={metadata.accept ? metadata.accept : "*"}
            />
          </>
        );
      default:
        console.log("Field type not found");
    }
  };

  return (
    <div className="w-2/3 m-auto">
      <div>
        {formElements.length !== 0 &&
          Object.keys(formElements).map((element, ind) => (
            <fieldset
              className="grid grid-cols-2 grid-flow-row gap-x-14"
              style={
                currStepper - 1 !== ind
                  ? { display: "none" }
                  : { display: "grid" }
              }
            >
              {formElements[ind].map((obj, index) => (
                <div className="mb-7 max-w-full" key={index}>
                  {renderForm(obj, formInputValues[currStepper])}
                </div>
              ))}
            </fieldset>
          ))}
      </div>
    </div>
  );
};

export default memo(CreateForm);
