import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import signIn from "../../assets/images/730_generated-removebg-preview.png";
// import { BORROWER, MEDIATOR, LENDER } from "../constant/index";
import logoHorseSrc from "../../assets/images/thar_logo_1.png";

import { registerUserApi } from "../../api/register/register.api";

const borrowerInputField = {
  // referenceId: {
  //   type: "text",
  //   label: "Reference Id",
  //   name: "referenceId",
  //   value: "",
  //   error: "",
  // },
  email: {
    type: "email",
    label: "Email",
    name: "email",
    value: "",
    error: "",
  },
  password: {
    type: "password",
    label: "Password",
    name: "password",
    value: "",
    error: "",
  },
  confirmPassword: {
    type: "password",
    label: "Confirm Password",
    name: "confirmPassword",
    value: "",
    error: "",
  },
  // mobileNumber: {
  //   type: "text",
  //   label: "Mobile Number",
  //   name: "mobileNumber",
  //   value: "",
  //   error: "",
  // },
};

const getRegisterFormPayload = (formDataObj) => {
  const currentUserRole = sessionStorage.getItem("role");
  let userRoleNumber = 0;
  if (currentUserRole === "borrower") {
    userRoleNumber = 3;
  } else if (currentUserRole === "mediator") {
    userRoleNumber = 2;
  } else if (currentUserRole === "lender") {
  } else {
    console.log("error");
    return;
  }

  const payload = Object.keys(formDataObj).reduce((prev, curr) => {
    return { ...prev, [curr]: formDataObj[curr].value };
  }, {});
  payload.account_type = userRoleNumber;
  return payload;
};

const Register = () => {
  const [formData, setFormData] = useState(borrowerInputField);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password.value === formData.confirmPassword.value) {
      try {
        // const registerFormPayload = getRegisterFormPayload(formData);
        let finalData = {};
        finalData.account_type = 3;
        finalData.email = formData.email.value;
        finalData.password = formData.password.value;
        if (
          Object.values(finalData).includes(null) ||
          Object.values(finalData).includes("")
        ) {
          alert("Please fill all the fields");
        } else {
          const res = await registerUserApi(finalData);
          if (res.status === 200) {
            alert("User registered successfully");
            navigate("/");
          }
          // register successful
          // navigate("/login");
        }
      } catch (err) {
        console.log(err);
        alert("Somehing went wrong. Please try again later.");
      }
    } else {
      alert("password does not match");
    }
  };

  const handleInputChange = (e) => {
    // let { name, value } = e.target;

    const name = e.target.name;
    const value = e.target.value;
    let currentData = { ...formData };
    currentData[name].value = value;
    setFormData(currentData);
  };

  return (
    <div className="w-full relative flex items-center justify-center">
      <div className="w-1/2 h-screen bg-indigo-500 flex items-center justify-center self-start sticky top-0">
        <img src={signIn} alt="" className="h-" />
      </div>
      <div className="w-1/2 h-full flex flex-col items-center justify-center mt-12 mb-12">
        <div className="w-[320px] h-[70px]  p-1">
          <img src={logoHorseSrc} alt="logo_image" className="w-full h-full" />
        </div>
        <form onSubmit={handleSubmit}>
          {/* <div className="mb-4">
            <InputElement
              inputKey={"referenceId"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div> */}
          <div className="mb-4">
            <InputElement
              inputKey={"email"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <InputElement
              inputKey={"password"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <InputElement
              inputKey={"confirmPassword"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div>
          {/* <div className="mb-4">
            <InputElement
              inputKey={"mobileNumber"}
              formData={formData}
              onChange={handleInputChange}
            />
          </div> */}

          <br />
          <div className="w-full text-center">
            <button
              className="py-2 px-4 uppercase text-sm bg-blue-300 rounded-md"
              type="submit"
            >
              REGISTER
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

function InputElement(props) {
  const { inputKey, children, formData, ...rest } = props;
  if (!formData[inputKey]) {
    return null;
  }

  const { label, name, value, type } = formData[inputKey];
  return (
    <>
      <InputLabel labelText={label} name={name} />
      <Input
        placeholder={`${label}...`}
        value={value}
        type={type}
        id={name}
        name={name}
        {...rest}
      />
    </>
  );
}

const Input = (props) => {
  const { value, name, type, onChange, placeholder, ...rest } = props;

  return (
    <>
      <input
        className="leading-10 indent-4 border-[1px] min-w-[100px] w-full max-w-full border-light-grey outline-none"
        type={type}
        value={value}
        onChange={onChange}
        {...rest}
        placeholder={placeholder}
        name={name}
      />
    </>
  );
};

function InputLabel(props) {
  const { labelText, name } = props;
  return (
    <label htmlFor={name} className="font-medium mb-1 text-base text-gray-500">
      {labelText}
    </label>
  );
}

export default Register;
